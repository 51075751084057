import React from "react";
import Head from "next/head";
import '../styles/scss/style.scss';
import "../styles/globals.css";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }) {
  const Router = useRouter();
  const canonicalUrl = (
    `https://naidhruvatechnologies.com` + (Router.asPath === "/" ? "" : Router.asPath)
  ).split("?")[0];

  return (
    <>
      <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0 user-scalable=0"
          />
          <meta
            httpEquiv="Content-Security-Policy"
            content="upgrade-insecure-requests"
          />
          <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-K5F7MGTV"></script>
          <meta name="google-site-verification" content="Hl15PXKnfWV_Mh_PPEiNKgpC-R7WnrEnetkkwnqMsxk" />
          <link rel="canonical" href={canonicalUrl} />
          {/* favicon begin */}
          <link rel="shortcut icon" href="/favicon.png" type="image/png" />
	        <link rel="icon" href="/favicon.png" type="image/png" />
          {/* favicon */}
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;


